import React, { useState } from 'react';
import Modal from './components/Modal';
import './Menu.css';

function Menu({ isOpen, onClose }) {
  const [modalContent, setModalContent] = useState(null);

  const menuItems = [
    { 
      title: '当サイトについて', 
      content: (
        <div>
          <h3>ポケカNavi</h3>
          <p>ポケモンカードに関する情報をご提供することを目指した個人が運用するサイトです。
            現在はポケカ検索GPTの単機能ですが、今後はカード検索機能をさらに充実させたり対戦環境の分析機能などを追加していく予定ですので、ぜひ定期的に訪問してみてください</p>
          <h3>ポケカ検索GPT BETA</h3>
          <p>テキスト分析AIを用いたポケモンカードの検索ツールです。<br/>自然言語でポケモンカードに関する質問をすることで、AIが答えてくれます。<br/>現状ではルールやカード効果の意味検索に対応していませんが、今後のアップデートで対応していく予定です。</p>
        </div>
      )
    },
    { 
      title: 'お問い合わせ', 
      content: (
        <div>
          <p>お問い合わせ・バグ報告・改善要望などは以下のメールアドレスまでお願いします：</p>
          <p><a href="mailto:pokecanavi@gmail.com">pokecanavi@gmail.com</a></p>
          {/* フォームなども追加可能 */}
        </div>
      )
    },
    { 
      title: '免責事項',
      content: (
        <div>
            <p>
    当サイトは、ポケモンカードに関する情報提供およびルールの解釈支援を目的としたサービスを提供していますが、公式のポケモンカードルールブックまたはポケモンカード公式ルール裁定とは異なる場合があります。以下の点にご留意ください。
  </p>

  <h2>1. 情報の正確性について</h2>
  <p>
    当サイトでは、可能な限り正確な情報提供を目指しておりますが、提供する情報が完全かつ正確であることを保証するものではありません。ポケモンカードに関する公式なルール裁定や最新情報については、必ずポケモンカード公式のリソースをご確認ください。
  </p>

  <h2>2. サイト利用における責任の限定</h2>
  <p>
    本サイトをご利用いただくことで得られる情報や検索結果について、その正確性、適用性、完全性についていかなる保証も行いません。また、利用者が本サイトを通じて得た情報を基に行う行為や決定によって生じた損害について、当サイトおよびその運営者は一切の責任を負いません。
  </p>

  <h2>3. 著作権および知的財産権</h2>
  <p>
    本サイトはポケモンカードの非公式ファンサイトであり、ポケモンおよびポケモンカードに関する著作権や商標権はすべて(株)クリーチャーズ、(株)ポケモンに帰属します。
  </p>
  <p>当サイトでは著作権の侵害について細心の注意を払っておりますが、万が一、著作権を侵害する情報が含まれている場合には、速やかに対応いたしますので、お問い合わせページよりご連絡ください</p>

  <h2>4. 外部リンクについて</h2>
  <p>
    当サイトは、ポケモンカードに関連する公式および非公式の外部サイトへのリンクを含む場合があります。外部サイトのコンテンツやプライバシーポリシーについて当サイトは一切の責任を負いません。リンク先サイトのご利用については、ご自身の判断で行ってください。
  </p>

  <h2>5. 免責事項の変更</h2>
  <p>
    本免責事項は、当サイトの運営状況やポケモンカードの公式ルールに基づき、予告なく変更されることがあります。最新の免責事項をご確認のうえ、サービスをご利用ください。
  </p>
        </div>
      )
    }
  ];

  const handleMenuClick = (content, title) => {
    setModalContent({ content, title });
    onClose(); // メニューを閉じる
  };

  return (
    <>
      <div className={`side-menu ${isOpen ? 'active' : ''}`}>
        <button className="close-button" onClick={onClose}>×</button>
        <nav className="menu-content">
          <ul>
            {menuItems.map((item, index) => (
              <li key={index}>
                <button 
                  className="menu-button"
                  onClick={() => handleMenuClick(item.content, item.title)}
                >
                  {item.title}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <Modal
        isOpen={modalContent !== null}
        onClose={() => setModalContent(null)}
        title={modalContent?.title}
      >
        {modalContent?.content}
      </Modal>
    </>
  );
}

export default Menu;