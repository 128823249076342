import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SearchForm from './SearchForm';
import Menu from './Menu';
import './App.css';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <HelmetProvider>
      <div className="App">
        <Helmet>
          <title>ポケカNavi</title>
          <meta name="description" content="ポケモンカードの検索・情報サイト" />
          <meta property="og:title" content="ポケカNavi" />
          <meta property="og:description" content="ポケモンカードの検索・情報サイト" />
          <meta name="twitter:title" content="ポケカNavi" />
          <meta name="twitter:description" content="ポケモンカードの検索・情報サイト" />
        </Helmet>

        <header className="app-header">
          <div className="header-content">
            <button 
              className="hamburger-button"
              onClick={toggleMenu}
              aria-label="メニューを開く"
            >
              <span className="hamburger-icon"></span>
            </button>
            <div className="header-titles">
              <h1 className="site-title">ポケカNavi</h1>
              <p className="service-title">
                ポケカ検索GPT
                <span className="beta-tag">BETA</span>
              </p>
            </div>
          </div>
        </header>

        {/* オーバーレイ */}
        <div 
          className={`overlay ${isMenuOpen ? 'active' : ''}`}
          onClick={closeMenu}
          role="presentation"
        />

        {/* サイドメニュー */}
        <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />

        <main className="main-content">
          <SearchForm />
        </main>

        <footer className="footer">
          <p>© {new Date().getFullYear()} PokecaNavi. All rights reserved.</p>
        </footer>
      </div>
    </HelmetProvider>
  );
}

export default App;