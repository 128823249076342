import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import './App.css';
import { API_BASE_URL } from './config';

// axiosインスタンスの作成（タイムアウト設定を含む）
const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000, // 60秒のタイムアウト
});

function SearchForm() {
    const [query, setQuery] = useState('');
    const [result, setResult] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStage, setLoadingStage] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const inputRef = useRef(null);
    const [composingText, setComposingText] = useState('');
  
    useEffect(() => {
        // markedの設定をカスタマイズ
        marked.setOptions({
            breaks: true, // 改行を<br>に変換
            gfm: true,    // GitHub Flavored Markdownを有効化
        });

        const fetchSuggestions = async () => {
          const currentQuery = query + composingText;
          if (currentQuery.length > 0) {
            try {
              const response = await axios.get(`${API_BASE_URL}/suggestions?q=${encodeURIComponent(currentQuery)}`);
              setSuggestions(response.data);
              setShowSuggestions(true);
            } catch (error) {
              console.error('Error fetching suggestions:', error);
              setSuggestions([]);
              setShowSuggestions(false);
            }
          } else {
            setSuggestions([]);
            setShowSuggestions(false);
          }
        };
      
        const timeoutId = setTimeout(fetchSuggestions, 300);
      
        return () => clearTimeout(timeoutId);
    }, [query, composingText]);
  
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setQuery(inputValue);
        setShowSuggestions(inputValue.length > 0);
    };
  
    const handleCompositionStart = (e) => {
        setComposingText(e.data || '');
    };

    const handleCompositionUpdate = (e) => {
        setComposingText(e.data || '');
    };

    const handleCompositionEnd = (e) => {
        setComposingText('');
    };
  
    const handleSuggestionClick = (suggestion) => {
      setQuery(suggestion);
      setShowSuggestions(true);
      inputRef.current.focus();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (query.trim()) {
            await performSearch(query);
        }
    };

    const performSearch = async (searchQuery) => {
      setQuery(searchQuery); // 検索クエリを入力欄に反映
      setIsLoading(true);
      setResult('');
      try {
        const response = await api.post('/search/start', { query: searchQuery });
        const { task_id } = response.data;
        
        while (true) {
          await new Promise(resolve => setTimeout(resolve, 2000));
          const statusResponse = await api.get(`/search/status/${task_id}`);
          setLoadingStage(statusResponse.data.step);
          
          if (statusResponse.data.status === 'completed') {
            setResult(statusResponse.data.result);
            break;
          } else if (statusResponse.data.status === 'error') {
            throw new Error(statusResponse.data.error);
          }
        }
      } catch (error) {
        console.error('Error during search:', error);
        setResult('検索中にエラーが発生しました: ' + error.message);
      } finally {
        setIsLoading(false);
        setLoadingStage('');
      }
    };
  
    const sampleQuestions = [
      "ピカチュウのカードをHPが高い順に教えて",
      "イーブイから進化するカードを教えて",
      "なかよしポフィンはどの商品に収録されている？"
    ];

    const wrapTablesWithScrollableDiv = (html) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const tables = doc.getElementsByTagName('table');
      
      for (let table of tables) {
        const wrapper = doc.createElement('div');
        wrapper.className = 'table-wrapper';
        table.parentNode.insertBefore(wrapper, table);
        wrapper.appendChild(table);
      }
      
      return doc.body.innerHTML;
    };

    const getLoadingMessage = (stage) => {
      switch (stage) {
        case '開始':
          return '検索を開始しています...';
        case 'キーワード抽出中':
          return 'キーワードを抽出しています...';
        case 'グラフ構造生成中':
          return 'グラフ構造を生成しています...';
        case '検索クエリ生成中':
          return '検索クエリを生成しています...';
        case 'データベース検索中':
          return 'データベースを検索しています...';
        case '回答生成中':
          return '回答を生成しています...';
        default:
          return '処理中...';
      }
    };
  
    return (
      <div className="search-form">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            id="search-query"
            name="search-query"
            value={query}
            onChange={handleInputChange}
            onCompositionStart={handleCompositionStart}
            onCompositionUpdate={handleCompositionUpdate}
            onCompositionEnd={handleCompositionEnd}
            onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
            onFocus={() => setShowSuggestions(true)}
            placeholder="質問を入力してください"
            autoComplete="off"
            ref={inputRef}
          />
          <button type="submit" disabled={isLoading || !query.trim()}>
            <span className="arrow">↑</span>
          </button>
        </form>
        {showSuggestions && suggestions.length > 0 && (
          <ul className="suggestions">
            {suggestions.map((suggestion, index) => (
              <li key={index} onMouseDown={() => handleSuggestionClick(suggestion)}>
                {suggestion}
              </li>
            ))}
          </ul>
        )}
        <div className="sample-questions">
          {sampleQuestions.map((question, index) => (
            <button
              key={index}
              onClick={() => performSearch(question)}
              disabled={isLoading}
            >
              {question}
            </button>
          ))}
        </div>
        
        <div className="search-results">
          {isLoading && (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <p>{getLoadingMessage(loadingStage)}</p>
            </div>
          )}
          {result && (
            <div 
              className="result" 
              dangerouslySetInnerHTML={{ 
                __html: DOMPurify.sanitize(
                  wrapTablesWithScrollableDiv(marked(result))
                ) 
              }} 
            />
          )}
        </div>
      </div>
    );
}

export default SearchForm;